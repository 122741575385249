import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationApi } from "helpers";

const name = "notification";
const initialState = [{messages: [], requests: [], invites: [], totalCount: 0}];

const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

export const notificationActions = { ...slice.actions, ...extraActions };
export const notificationReducer = slice.reducer;

function createReducers() {
  return {
    createNotification, updateNotification, getOneNotification, getListNotification, deleteNotification,
  };

  function createNotification(state, { payload }) {

    return state;
  }

  function updateNotification(state, { payload }) {

    return state;
  }

  function getOneNotification(state, { payload }) {

    return state;
  }

  function getListNotification(state, { payload }) {

    return state;
  }

  function deleteNotification(state, { payload }) {

    return state;
  }
}

function createExtraActions() {
    return {
      create: create(),
      update: update(),
      getOne: getOne(),
      getList: getList(),
      deleteOne: deleteOne()
    };

    function create() {
        return createAsyncThunk(`${name}/create`, async (data) => await notificationApi.create(data));
    }

    function update() {
        return createAsyncThunk(`${name}/update`, async (data) => await notificationApi.update(data));
    }

    function getOne() {
        return createAsyncThunk(`${name}/getOne`, async (data) => await notificationApi.getOne(data));
    }

    function getList() {
        return createAsyncThunk(`${name}/getList`, async (data) => await notificationApi.getList(data));
    }

    function deleteOne() {
        return createAsyncThunk(`${name}/deleteOne`, async (data) => await notificationApi.delete(data));
    }
}

function createExtraReducers() {
    return {
      ...create(), ...update(), ...getOne(), ...getList(), ...deleteOne()
    };
  
    function create() {
        const { fulfilled } = extraActions.create;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }

    function update() {
        const { fulfilled } = extraActions.update;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }

    function getOne() {
        const { fulfilled } = extraActions.getOne;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }

    function getList() {
        const { fulfilled } = extraActions.getList;
        return {
          [fulfilled]: (state, action) => {

            state = action.payload;
            return state;
          },
        };
    }

    // function list() {
    //     const { fulfilled } = extraActions.list;
    //     return {
    //       [fulfilled]: (state, action) => {
    //         state.list = action.payload.result
    //       },
    //     };
    //   }

    function deleteOne() {
        const { fulfilled } = extraActions.deleteOne;
        return {
          [fulfilled]: (state, action) => {
            return;
          },
        };
    }
}
