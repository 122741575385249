import ApiDefault from "./ApiDefault";

const PATH = 'user';

export default class User extends ApiDefault {
  async login(data) {
    return this.post('login_check', data);
  }

  async register(data) {
    return this.post(PATH, data);
  }

  async update(data) {
    return this.patch(PATH + `/${data.id}`, data);
  }

  async confirmPhone(id, phoneCode) {
    return this.post(PATH + '/confirm-phone', { id, phoneCode });
  }

  async resentPhoneCode(id) {
    return this.get(PATH + '/resent-phone-code/' + id);
  }

  async activateAccount(token) {
    return this.get(PATH + '/activate-account/' + token);
  }

  async resetPasswordRequest(token) {
    return this.post(PATH + '/reset-password-request', { token });
  }

  async resetPassword(token, password) {
    return this.post(PATH + '/reset-password', { token, password });
  }

  async updatePassword(data) {
    return this.post(PATH + '/password', data);
  }

  async getPersonalInfo() {
    return this.get(PATH + '/personal-info');
  }

  async upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("avatarPhoto", file);

    return this.post(PATH + "/avatar", formData, onUploadProgress);
  }

  async getSubscribe() {
    return this.get(PATH + '/subscribe');
  }

  async getAgencySubscribes() {
    return this.get(PATH + '/agency/subscribes');
  }

  async setAgencySubscribes(data) {
    return this.put(PATH + '/agency/subscribes', data);
  }

  async updateMyCity(data) {
    return this.patch(PATH + '/me/city', data);
  }

  async updateShowM2Table(data) {
    return this.patch(PATH + `/me/showdb?showM2Table=${data}`);
  }

  async responsibleAgents() {
    return this.get(PATH + '/responsible/agents');
  }
}