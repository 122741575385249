import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from 'react';

export function Breadcrumbs({ linkName, linkPath, title }) {
  const breadcrumbs = [
    <Link to={"/" + linkPath} key="1" style={{ textDecoration: "none" }}>
      <Box sx={{ display: "flex", color: "primary.main", mr: 4 }}>
        <NavigateBeforeIcon />
        <Typography key="1">
          {linkName}
        </Typography>
      </Box>
    </Link>,
    <Typography key="2" color="text.primary" variant="h2">
      {title}
    </Typography>,
  ];

  return <Stack spacing={2}>
    <MuiBreadcrumbs separator={""} aria-label="breadcrumb">
      {breadcrumbs}
    </MuiBreadcrumbs>
  </Stack>
}