import { Autocomplete, CircularProgress, FormControl, TextField, Typography } from "@mui/material";
import { debounce } from '@mui/material/utils';
import { getTypeShortName, locationApi } from "helpers";
import React, { Fragment, useEffect, useMemo, useState } from "react";

export function AddressAutocomplete({ disabled, field, formik, label, placeholder, required }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const value = formik.values[field];

  const fetch = useMemo(() => debounce((inputValue, field) => {
    const state = formik.values.state?.id
    const city = formik.values.city?.id
    const cityRegion = formik.values.cityRegion?.id
    const citySubregion = formik.values.citySubregion?.id
    const street = formik.values.street?.id
    const residentialComplex = formik.values.residentialComplex?.id

    let promise;

    switch (field) {
      case "street":
        promise = locationApi.streets({search: inputValue, state, city, cityRegion, citySubregion, residentialComplex});
        break;
      case "citySubregion":
        promise = locationApi.citySubregions({search: inputValue, state, city, cityRegion, street, residentialComplex});
        break;
      case "cityRegion":
        promise = locationApi.cityRegions({search: inputValue, state, city, citySubregion, street, residentialComplex});
        break;
      case "city":
        promise = locationApi.cities({search: inputValue, state, cityRegion, citySubregion, street, residentialComplex});
        break;
      case "residentialComplex":
        promise = locationApi.residentialComplexes({search: inputValue, state, cityRegion, citySubregion, street });
        break;
      case "state":
      default:
        promise = locationApi.states({state, city, cityRegion });
        break;
    }

    promise.then(({ result }) => {
      setLoading(false)
      setOptions(result)
    });
  }, 400,), [formik],)


  const handleChangeValuePreview = (event, newValue) => {

    if (event) {

      if (JSON.parse(localStorage.getItem('currentPreview')) !== null) {

        const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

        const currentApartment = apartmentsList.map(apartment => {
          if (apartment.id === formik.values.id) {
            return {...apartment, [field]: newValue}
          }
          return apartment;
        })
    
        localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
      }
    }
  }

  useEffect(() => {
    if (!open || (value && inputValue === getTypeShortName(value.type) + value.name)) {
      return undefined;
    }

    setLoading(true);
    fetch(inputValue, field);

  }, [open, field, inputValue, value, fetch]);

  const handleChange = (event, newValue) => {

    formik.setFieldValue(field, newValue, true)
    const mapAddress = ['state', 'city', 'cityRegion', 'citySubregion', 'street'];
    if (newValue === null || (formik.values.field !== null && newValue !== null)) {
      const index = mapAddress.indexOf(field);
      let result = [];
      if (index !== -1) {
        result = [...mapAddress.slice(index + 1)];
      }
      if (result.length) {
        for(const item of result) {
          formik.setFieldValue(item, null, true)
        }
      }
    }
    formik.setFieldValue(field, newValue, true)

    handleChangeValuePreview(event, newValue)
  }

  return <FormControl fullWidth>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }}>
      {label}
    </Typography>
    <Autocomplete
      disabled={disabled}
      // filterOptions={(x) => x}
      fullWidth
      open={open}
      onChange={(event, newValue) => handleChange(event, newValue)}
      // onChange={(e, newValue) => formik.setFieldValue(field, newValue, true)}
      onClose={() => {setOpen(false)}}
      blurOnSelect={true}
      onInputChange={(event, newInputValue) => {setInputValue(newInputValue)}}
      onOpen={() => {setOpen(true);}}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => getTypeShortName(option.type) + option.name}
      // getOptionDisabled={() => loading}
      options={options}
      loading={loading}
      renderInput={(params) => (<TextField
        {...params}
        error={formik.errors[field] && formik.touched[field]}
        helperText={formik.touched[field] && formik.errors[field]}
        InputProps={{
          ...params.InputProps, endAdornment: (<Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </Fragment>),
        }}
        inputProps={{
          ...params.inputProps, style: {
            padding: 0,
            height: '22px'
          },
        }}
        placeholder={placeholder}
        variant="filled"
      />)}
      renderOption={(props, option) => {
        return (<li {...props} key={option.id}>
          {getTypeShortName(option.type) + option.name}
        </li>);
      }}
      sx={{ mb: 0.5, mt: 1 }}
      variant="filled"
      value={value}
    />
  </FormControl>;
}