import React, { useState, useEffect } from 'react';
import { Button, IconButton, Badge, Box, Typography, Modal, Divider, MenuItem, ListItemIcon, ListItemButton } from "@mui/material";
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import { agentApi, useDocumentTitle, history, notificationApi } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { authActions, messageActions, notificationActions } from "store";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { Avatar, ContextMenu } from "components";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ListSubheader from '@mui/material/ListSubheader';

export default function Notification() {

    const { t } = useTranslation(["translation", "form"]);
    const dispatch = useDispatch();

    const [invites, setInvites] = useState([])
    const [requests, setRequests] = useState([])
    // const [messages, setMessages] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [openRequest, setOpenRequest] = useState(false);
    const [currentRequestAgent, setCurrentRequestAgent] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useSelector(state => state.auth);

    const handleOpenRequest = () => setOpenRequest(true);
    const handleCloseRequest = () => setOpenRequest(false);

    const clickOnNotification = (event, key) => {
      notificationApi.updateAll({type: 'request'}).then(result => {

        setRequests([])
      });

      setOpenRequest(false)
    }

    useEffect(() => {

      dispatch(notificationActions.getList()).then((result) => {

        setRequests(result.payload.requests);
        setInvites(result.payload.invites);
        // setMessages(result.payload.messages);

        setTotalCount(result.payload.totalCount);
      });

    }, [dispatch]);

    const handleAnchorEl = (item, event) => {

      setCurrentRequestAgent(item.sender);
      setAnchorEl(event.currentTarget);
    }

    const handleApproveInvite = (event) => {

      console.log(currentRequestAgent.id)
      
      agentApi.joinInviteAgency(currentRequestAgent.id).then(({total_requests}) => {
  
        dispatch(authActions.getPersonalInfo()).then(({payload}) => {

          setInvites(payload.result.invitesForMe.invites);
          setTotalCount(payload.result.invitesForMe.totalCount);

          if (payload.result.invitesForMe.totalCount === 0) {
            handleCloseRequest();
            setAnchorEl(null);
          }

        });
  
        dispatch(messageActions.addSuccess(t('approveRequestSuccess')));
      });
    }
    
    const handleRemoveInvite = (event) => {
  
      agentApi.refusalInviteAgency(currentRequestAgent.id).then(({total_requests}) => {
  
        dispatch(authActions.getPersonalInfo()).then(({payload}) => {

          setInvites(payload.result.invitesForMe.invites);
          setTotalCount(payload.result.invitesForMe.totalCount);

          if (payload.result.invitesForMe.totalCount === 0) {
            handleCloseRequest();
            setAnchorEl(null);
          }
        });

        dispatch(messageActions.addSuccess(t('romoveRequestSuccess')));
      });
    }
    
    const handleInviteToAgency = () => {
      
    }

    const notificationsLabel = (count) => {
      if (count === 0) {
        return 'no notifications';
      }
      if (count > 99) {
        return 'more than 99 notifications';
      }
      return `${count} notifications`;
    }

  return (
    <>
      <IconButton width={24} height={24} onClick={() => handleOpenRequest()} aria-label={notificationsLabel(totalCount)}>
        <Badge badgeContent={totalCount} color="primary">
          <NotificationsActiveOutlined />
        </Badge>
      </IconButton>

      <Modal open={openRequest} onClose={handleCloseRequest} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4}}>
          <Typography variant="h2" sx={{fontWeight: "bold", mb: 2.5, ml: 2.0}}>
            {totalCount === 0 ? t('notFoundNotification') : t('notification')}
          </Typography>
          {
            requests.length 
            ? 
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}} subheader={
              totalCount === 0 ? null : <ListSubheader>{t('notificationRequestsForMyAgency')}</ListSubheader>
            }>
              {
                requests.map((request, key) => 
                  <>
                    <ListItem key={key} alignItems="flex-start">
                      <ListItemButton component={Link} to="/crm/agents"                 
                        onClick={(event) => window.location.pathname == "/crm/agents" == clickOnNotification(event, key)}
                      >
                        <ListItemAvatar>
                          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                          <Avatar sx={{color: "#FF8A5B", marginRight: "20px"}} />
                        </ListItemAvatar>
                        <ListItemText primary={request.title}
                          secondary={
                            <Typography component="span" variant="body2" sx={{color: 'text.primary', display: 'inline'}}>
                              {request.sender.email}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                )
              }
            </List> : null 
          }

          {
            invites.length 
            ? 
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}} subheader={
              totalCount === 0 ? null : <ListSubheader>{t('notificationInvitesForMe')}</ListSubheader>
            }>
              {
                invites.map((invite, key) => 
                  <>
                    <ListItem key={key} alignItems="flex-start" secondaryAction={
                      <IconButton onClick={(event) => handleAnchorEl(invite, event)} aria-label="menu" edge="end" sx={{backgroundColor: 'transparent', height: 24, width: 24}}>
                        <MoreVertIcon sx={{height: 20, width: 20}} />
                      </IconButton>
                    }>
                      <ListItemAvatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar sx={{color: "#FF8A5B", marginRight: "20px"}} />
                      </ListItemAvatar>
                      <ListItemText primary={invite.title}
                        secondary={
                          <Typography component="span" variant="body2" sx={{color: 'text.primary', display: 'inline'}}>
                            {invite.sender.email}
                          </Typography>
                        }
                      />
                      <ContextMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} >
                        <MenuItem onClick={(event) => {setAnchorEl(null); handleApproveInvite(event)}}>
                          <ListItemIcon>
                            <DoneOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="text">{t('approveRequest')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={(event) => {setAnchorEl(null); handleRemoveInvite(event)}}>
                          <ListItemIcon>
                            <ClearOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="text">{t('romoveRequest')}</Typography>
                        </MenuItem>
                      </ContextMenu>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                )
              }
            </List> : null 
          }
        </Box>
      </Modal>
    </>
  );
}
